import React from 'react';

function Register() {
    const [username, setUsername] = React.useState('username');
    const [password, setPassword] = React.useState('password');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        fetch('/register', {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
            },
            "body": JSON.stringify({ username, password })
        }).then(
            (resp) => {
                if (resp.status === 200) {
                    return true
                } else {
                    return false
                }
            });
    }

    return (
        <>
            <b>Register</b>
            <form onSubmit={handleSubmit}>
                <input required type="text" value={username} placeholder="Enter Username" autoComplete="username" onChange={e => setUsername(e.target.value)}></input>
                <input required type="password" value={password} placeholder="Enter password" autoComplete="current-password" onChange={e => setPassword(e.target.value)}></input>
                <button type="submit">Register</button>
            </form>
            <b>Close Register</b>
        </>
    )
}

export default Register;
