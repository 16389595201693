import React from 'react';
import { authContext } from '../components/Auth';
import { localStoreContext } from '../components/LocalStore'
import { useNavigate } from "react-router-dom";

function Login() {
    const [username, setUsername] = React.useState('username');
    const [password, setPassword] = React.useState('password');
    const [error, setError] = React.useState("");

    const auth = React.useContext(authContext);
    const localStore = React.useContext(localStoreContext);

    const navigate = useNavigate();
    React.useEffect(() => {
        if (localStore.store.authenticated) {
            navigate("/");
        }
    }, [localStore.store.authenticated, navigate]

    )

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        auth.login(username, password).then((loginResult) => {
            setError(loginResult.error);
            if (loginResult.error) {
                return;
            }

            const action = {
                authenticated: loginResult.authenticated,
                name: loginResult.name
            };

            localStore.insert(action);
            localStore.updateLocalStorage();

        })
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <input required type="text" value={username} placeholder="Enter Username" autoComplete="username" onChange={e => setUsername(e.target.value)}></input>
                <input required type="password" value={password} placeholder="Enter password" autoComplete="current-password" onChange={e => setPassword(e.target.value)}></input>
                <button type="submit">Login</button>
            </form>
            {error && <p>{error}</p>}
        </>
    )
}

export default Login;
