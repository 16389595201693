import './App.css';
import Layout from './components/Layout'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './pages/Login'
import Register from './pages/Register'

function App() {
    return (
        <BrowserRouter>

            <Routes>
                <Route path="/" element={<Layout />} />

                <Route path="register" element={<Register />} />
                <Route path="login" element={<Login />} />

            </Routes>
        </BrowserRouter>
    );
}

export default App;
