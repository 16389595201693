import * as React from 'react';


interface authStateResult {
    authenticated: boolean;
    error: string;
    name: string;
}

function validatePassword(password: string): [boolean, string] {
    if (password.length < 8) {
        return [false, "Password too short, expect 8 characters"]
    }
    return [true, ""]
}

const authContext = React.createContext({
    // Mutable ref object
    login: (username: string, password: string): Promise<authStateResult> => {
        return new Promise(() => {
            return {
                authenticated: false,
                error: "Not implemented",
                name: ""
            }
        })
    },
    register: (username: string, password: string) => { },
    logout: () => { },
});

const AuthProvider = ({ children }: any) => {
    function register(username: string, password: string): authStateResult {
        if (username && password) {
            let [validPassword, errorPassword] = validatePassword(password);
            if (!validPassword) {
                return { "name": "", "authenticated": false, "error": errorPassword }
            }

            fetch('/register', {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                },
                "body": JSON.stringify({ username, password })
            }).then(
                (resp) => {
                    if (resp.status === 200) {
                        return { "name": "username", "authenticated": true, "error": errorPassword }
                    } else {
                        return { "name": "", "authenticated": false, "error": "Login failed" }
                    }
                });
        }
        return { "name": "", "authenticated": false, "error": "Username and password required" }
    }

    async function login(username: string, password: string): Promise<authStateResult> {
        if (username && password) {
            let result;
            let [validPassword, errorPassword] = validatePassword(password);
            if (!validPassword) {
                return { "name": "", "authenticated": false, "error": errorPassword }
            }

            await fetch('/login', {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                },
                "body": JSON.stringify({ username, password })
            }).then(
                (resp) => {
                    if (resp.status === 200) {
                        result = { "name": username, "authenticated": true, "error": "" }
                    } else {
                        result = { "name": "", "authenticated": false, "error": 'Login Failed' }
                    }
                })
                .catch((e) => {
                    result = { "name": "", "authenticated": false, "error": e }
                });

            return result || { "name": "", "authenticated": false, "error": 'Login Failed' }

        } else {
            return { "name": "", "authenticated": false, "error": 'Username and password required' }
        }
    }

    async function logout(): Promise<boolean> {
        return fetch('/logout', {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
            }
        }).then(
            (resp) => {
                if (resp.status === 200) {
                    return true
                } else {
                    return false
                }
            });
    }

    return (
        <authContext.Provider value={{ login, logout, register }}>
            {children}
        </authContext.Provider>
    )
}

export default AuthProvider;
export { authContext };
