import Users from './Users'
import AuthBar from './AuthBar'

function Layout() {
    return (
        <div className="App">
            <p><b>App</b></p>
            <Users></Users>
            <AuthBar></AuthBar>
        </div>
    )
}

export default Layout;
