import React from 'react';

type user = {
    id: number,
    name: string
}

function Users() {
    const [users, setUsers] = React.useState<user[]>([]);

    React.useEffect(() => {
        fetch('/api/users')
            .then(
                resp => {
                    switch (resp.status) {
                        case 401:
                            setUsers([]);
                            break;
                        default:
                            resp.json().then(
                                users => {
                                    setUsers(users)
                                }
                            );
                    }
                })
    }, []);

    return (
        <>
            <h1 className="text-3xl font-bold underline">Users</h1>
            <br></br>
            <ul>
                {users.map((user) =>
                    <div key={user.id.toString()}>
                        <p>
                            {user.id}: {user.name}
                        </p>
                    </div>
                )}
            </ul>
        </>
    )
}

export default Users;
