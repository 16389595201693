import Login from '../components/Login'

function LoginPage() {
    return (
        <>
            <Login></Login>
        </>
    )
}

export default LoginPage;
