import React from 'react'

const DEFAULT_STORE = "sessionData"

const localStoreContext = React.createContext(
    {
        store: { name: "", authenticated: false },
        loadStore: (name: string = DEFAULT_STORE) => { },
        updateStore: (action: any) => { },
        updateLocalStorage: (name: string = DEFAULT_STORE) => { },
        insert: (action: any) => { },
        get: (key: string): any => null
    }
)

const LocalStoreProvider = ({ children }: any) => {
    const storeReducer = (state: any, action: any) => {
        if (action == null) {
            return state;
        } else {
            return { ...state, ...action };
        }
    }

    const [store, updateStore] = React.useReducer(storeReducer, {});

    React.useEffect(
        () => {
            loadStore()
        }, []);

    React.useEffect(
        () => {
            updateLocalStorage();
        }, [store]);

    const loadStore = () => {
        let data = localStorage.getItem(DEFAULT_STORE);
        if (data == null) {
            return {};
        }
        data = JSON.parse(data);
        if (data == null) {
            return {};
        }
        updateStore(data);
        return data;
    }

    const updateLocalStorage = () => {
        let localStoreString = JSON.stringify(store);
        localStorage.setItem(DEFAULT_STORE, localStoreString);
    }

    const insert = (action: any) => {
        updateStore(action);
    }

    const get = (key: string): Object => {
        return store[key];
    }

    return (<localStoreContext.Provider value={{ store, loadStore, updateStore, updateLocalStorage, insert, get }}>
        {children}
    </localStoreContext.Provider>)
}

export default LocalStoreProvider;
export { localStoreContext };
